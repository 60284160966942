<template>
    <div :class="$style.card">
        <div :class="$style.cardInner">
            <div :class="$style.groupWrapper">
                <div :class="$style.groupWrapper">
                    <div :class="$style.groupParent">
                        <div :class="$style.groupContainer">
                            <div :class="$style.profileCardWrapper">
                                <div :class="$style.profileCard">
                                    <div :class="$style.header">
                                        <img
                                            :class="$style.cardHeaderIcon"
                                            alt=""
                                            src="card-header.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :class="$style.groupDiv">
                            <div :class="$style.groupParent1">
                                <div :class="$style.buttonParent">
                                    <div :class="$style.button">
                                        <img
                                            :class="$style.shareAllOutlineIcon"
                                            alt=""
                                            src="share-all-outline.svg">
                                        <div :class="$style.text">
                                            Share
                                        </div>
                                    </div>
                                    <div :class="$style.button1">
                                        <img
                                            :class="$style.bookmarkIcon"
                                            alt=""
                                            src="bookmark.svg">
                                        <div :class="$style.text">
                                            Save
                                        </div>
                                    </div>
                                </div>
                                <div :class="$style.groupFrame">
                                    <div :class="$style.badgeParent">
                                        <div :class="$style.badgeParent">
                                            <div :class="$style.badge">
                                                <img
                                                    :class="$style.bookmarkIcon"
                                                    alt=""
                                                    src="bell.svg">
                                                <div :class="$style.text">
                                                    New
                                                </div>
                                            </div>
                                            <div :class="$style.badge1">
                                                <img
                                                    :class="$style.bookmarkIcon"
                                                    alt=""
                                                    src="users-group.svg">
                                                <div :class="$style.text">
                                                    Officer
                                                </div>
                                            </div>
                                            <div :class="$style.badge2">
                                                <img
                                                    :class="$style.bookmarkIcon"
                                                    alt=""
                                                    src="user.svg">
                                                <div :class="$style.text">
                                                    Air Force
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="$style.title">
                                Description
                            </div>
                            <div :class="$style.preview">
                                <p :class="$style.aComprehensiveFramework">
                                    A comprehensive framework that identifies lifecycle education and training requirements, training support resources, and minimum requirements for the
                                    intelligence officer specialty. The framework will provide a clear career path to success and will instill rigor in all
                                </p>
                                <p :class="$style.aComprehensiveFramework">
                                    aspects of career field training.
                                </p>
                            </div>
                            <div :class="$style.groupParent2">
                                <div :class="$style.frameContainer">
                                    <div :class="$style.frameDiv">
                                        <div :class="$style.frameWrapper1">
                                            <div :class="$style.commentsSaveWrapper">
                                                <div :class="$style.commentsSave">
                                                    <div :class="$style.likes">
                                                        <img
                                                            :class="$style.checkCircleIcon"
                                                            alt=""
                                                            src="check-circle.svg">
                                                        <div :class="$style.publishedOnJan">
                                                            Published on Jan 31
                                                        </div>
                                                    </div>
                                                    <div :class="$style.likes">
                                                        <img
                                                            :class="$style.checkCircleIcon"
                                                            alt=""
                                                            src="clock.svg">
                                                        <div :class="$style.publishedOnJan">
                                                            Last Updated on Jan 31
                                                        </div>
                                                    </div>
                                                    <div :class="$style.likes2">
                                                        <img
                                                            :class="$style.checkCircleIcon"
                                                            alt=""
                                                            src="archive.svg">
                                                        <div :class="$style.text">
                                                            124 items
                                                        </div>
                                                    </div>
                                                    <div :class="$style.likes2">
                                                        <img
                                                            :class="$style.checkCircleIcon"
                                                            alt=""
                                                            src="message-caption.svg">
                                                        <div :class="$style.text">
                                                            12 comments
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="$style.button2">
                                    <div :class="$style.text">
                                        Changelog
                                    </div>
                                    <img
                                        :class="$style.bookmarkIcon"
                                        alt=""
                                        src="collection.svg">
                                </div>
                                <div :class="$style.button2">
                                    <div :class="$style.text">
                                        Changelog
                                    </div>
                                    <img
                                        :class="$style.bookmarkIcon"
                                        alt=""
                                        src="collection.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "Spotlight",
  props: {
    headerImage: {
      type: String,
      required: true
    },
    shareIcon: {
      type: String,
      default: 'share-all-outline.svg'
    },
    saveIcon: {
      type: String,
      default: 'bookmark.svg'
    },
    badges: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Description'
    },
    description: {
      type: Array,
      default: () => []
    },
    additionalInfo: {
      type: Array,
      default: () => []
    },
    buttons: {
      type: Array,
      default: () => []
    }
  }
};
</script>


<style module>
.cardHeaderIcon {
    align-self: stretch;
    border-radius: 8px;
    max-width: 100%;
    overflow: hidden;
    height: 224px;
    flex-shrink: 0;
    object-fit: contain;
    }
    .header {
    align-self: stretch;
    height: 222px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 16px;
    box-sizing: border-box;
    }
    .profileCard {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    width: 387px;
    height: 255.5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    }
    .profileCardWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 387px;
    height: 255.5px;
    }
    .groupContainer {
    width: 387px;
    position: relative;
    height: 255.5px;
    }
    .shareAllOutlineIcon {
    width: 12px;
    position: relative;
    height: 12px;
    }
    .text {
    position: relative;
    line-height: 150%;
    font-weight: 500;
    }
    .button {
    position: absolute;
    top: 0px;
    left: 86px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 8px;
    }
    .bookmarkIcon {
    width: 12px;
    position: relative;
    height: 12px;
    overflow: hidden;
    flex-shrink: 0;
    }
    .button1 {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 8px;
    }
    .buttonParent {
    position: absolute;
    top: 0px;
    left: 585px;
    width: 175px;
    height: 40px;
    }
    .badge {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 12px;
    gap: 4px;
    }
    .badge1 {
    position: absolute;
    top: 0px;
    left: 205px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 12px;
    gap: 4px;
    }
    .badge2 {
    position: absolute;
    top: 0px;
    left: 88px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 12px;
    gap: 4px;
    }
    .badgeParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 292px;
    height: 25px;
    }
    .groupFrame {
    position: absolute;
    top: 7.45px;
    left: 0px;
    width: 292px;
    height: 25px;
    text-align: center;
    font-size: 14px;
    }
    .groupParent1 {
    position: absolute;
    top: 0px;
    left: 0.06px;
    width: 760px;
    height: 40px;
    }
    .title {
    position: absolute;
    top: 52px;
    left: 0px;
    line-height: 150%;
    font-family: Roboto;
    display: inline-block;
    width: 760.1px;
    }
    .aComprehensiveFramework {
    margin: 0;
    }
    .preview {
    position: absolute;
    top: 88px;
    left: 0px;
    font-size: 20px;
    line-height: 150%;
    display: inline-block;
    width: 757px;
    }
    .checkCircleIcon {
    width: 20px;
    position: relative;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    }
    .publishedOnJan {
    position: relative;
    line-height: 150%;
    }
    .likes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    }
    .likes2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    font-family: Inter;
    }
    .commentsSave {
    width: 535px;
    height: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    }
    .commentsSaveWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    }
    .frameWrapper1 {
    width: 732px;
    margin: 0 !important;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0px;
    box-sizing: border-box;
    z-index: 0;
    }
    .frameDiv {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 757.1px;
    height: 45px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 4px 1px;
    box-sizing: border-box;
    }
    .frameContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 757.1px;
    height: 45px;
    font-size: 14px;
    font-family: Roboto;
    }
    .button2 {
    position: absolute;
    top: 4.63px;
    left: 628.06px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 8px;
    }
    .groupParent2 {
    position: absolute;
    top: 250px;
    left: 0px;
    width: 757.1px;
    height: 45px;
    }
    .groupDiv {
    width: 760.1px;
    position: relative;
    height: 295px;
    }
    .groupParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1211px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    }
    .groupWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1211px;
    height: 295px;
    }
    .cardInner {
    align-self: stretch;
    position: relative;
    height: 295px;
    }
    .card {
    width: 100%;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    height: 356px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    text-align: left;
    font-size: 16px;
    font-family: Inter;
    }
    </style>
  