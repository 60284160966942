<template>
    <div :class="$style.content">
        <div :class="$style.contentLayouts">
            <!-- <div :class="$style.contentLayoutsInner">
                <div :class="$style.pageHeaderWrapper">
                    <div :class="$style.pageHeader">
                        <div :class="$style.welcomeX">
                            {{ frameworkTitle }}
                        </div>
                    </div>
                </div>
            </div> -->
            <div :class="$style.card">
                <!-- <div :class="$style.contentLayoutsInner"> -->
                <!-- <div :class="$style.pageHeaderWrapper"> -->
                <a :href="frameworkLink"  target="_blank">
                    <div :class="$style.spotlightTitle">
                        Spotlight: {{ frameworkTitle }}
                    </div>
                </a>
                <!-- </div> -->
                <!-- </div> -->
                <br>
                <div :class="$style.cardInner">
                    <div :class="$style.groupWrapper">
                        <div :class="$style.groupWrapper">
                            <div :class="$style.groupParent">
                                <div :class="$style.groupContainer">
                                    <div :class="$style.profileCardWrapper">
                                        <div :class="$style.profileCard">
                                            <div :class="$style.header">
                                                <a :href="frameworkLink"  target="_blank">
                                                    <img v-if="thumbnailSrc" :class="$style.cardHeaderIcon" alt="" :src="thumbnailSrc" />
                                                    <img v-else :class="$style.cardHeaderIcon" alt="" src="@/assets/no-thumbnail.png" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="$style.groupDiv">
                                    <div :class="$style.groupParent1">
                                        <div :class="$style.buttonParent">
                                            <div :class="$style.button">
                                                <img :class="$style.shareAllOutlineIcon" alt=""
                                                    src="@/components/eccr/assets/share-all-outline.svg">
                                                <div :class="$style.text">
                                                    Share
                                                </div>
                                            </div>
                                            <div :class="$style.button1">
                                                <img :class="$style.fileIcon" alt=""
                                                    src="@/components/eccr/assets/bookmark.svg">
                                                <div :class="$style.text">
                                                    Save
                                                </div>
                                            </div>
                                        </div>
                                        <div :class="$style.groupFrame">
                                            <div :class="$style.badgeParent">
                                                <div :class="$style.badgeParent">
                                                    <div :class="$style.badge">
                                                        <img :class="$style.fileIcon" alt=""
                                                            src="@/components/eccr/assets/bell.svg">
                                                        <div :class="$style.text">
                                                            New
                                                        </div>
                                                    </div>
                                                    <div :class="$style.badge1">
                                                        <img :class="$style.fileIcon" alt=""
                                                            src="@/components/eccr/assets/users-group.svg">
                                                        <div :class="$style.text">
                                                            Officer
                                                        </div>
                                                    </div>
                                                    <div :class="$style.badge2">
                                                        <img :class="$style.fileIcon" alt=""
                                                            src="@/components/eccr/assets/user.svg">
                                                        <div :class="$style.text">
                                                            Air Force
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.title">
                                        Description
                                    </div>
                                    <div :class="$style.preview">
                                        <p :class="$style.aComprehensiveFramework">
                                            {{ frameworkDescription }}
                                        </p>
                                    </div>
                                    <div :class="$style.groupParent2">
                                        <div :class="$style.frameContainer">
                                            <div :class="$style.frameDiv">
                                                <div :class="$style.frameWrapper1">
                                                    <div :class="$style.commentsSaveWrapper">
                                                        <div :class="$style.commentsSave">
                                                            <div :class="$style.likes">
                                                                <img :class="$style.checkCircleIcon" alt=""
                                                                    src="@/components/eccr/assets/check-circle.svg">
                                                                <div :class="$style.publishedOnJan">
                                                                    Published on {{ dateCreated }}
                                                                </div>
                                                            </div>
                                                            <div :class="$style.likes">
                                                                <img :class="$style.checkCircleIcon" alt=""
                                                                    src="@/components/eccr/assets/clock.svg">
                                                                <div :class="$style.publishedOnJan">
                                                                    Last Updated on {{ dateLastModified }}
                                                                </div>
                                                            </div>
                                                            <div :class="$style.likes2">
                                                                <img :class="$style.checkCircleIcon" alt=""
                                                                    src="@/components/eccr/assets/archive.svg">
                                                                <div :class="$style.text">
                                                                    {{ itemsCount }} items
                                                                </div>
                                                            </div>
                                                            <!-- <div :class="$style.likes2">
                                                                <img :class="$style.checkCircleIcon" alt=""
                                                                    src="@/components/eccr/assets/message-caption.svg">
                                                                <div :class="$style.text">
                                                                    12 comments
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div :class="$style.button2">
                                            <div :class="$style.text">
                                                <a :href="frameworkLink">Changelog</a>
                                            </div>
                                            <img :class="$style.fileIcon" alt=""
                                                src="@/components/eccr/assets/collection.svg">
                                        </div> -->
                                        <!-- <div :class="$style.button2">
                                            <div :class="$style.text">
                                                <a :href="frameworkURI">Framework</a>
                                            </div>
                                            <img :class="$style.fileIcon" alt=""
                                                src="@/components/eccr/assets/collection.svg">
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "CatalogSpotlight",
    props: {
        thumbnailSrc: {
            type: String
        },
        frameworkTitle: {
            type: String,
            required: true
        },
        frameworkDescription: {
            type: String,
            required: true
        },
        frameworkURI: {
            type: String,
            required: true
        },
        frameworkLink: {
            type: String,
            required: true
        },
        dateCreated: {
            type: String,
            required: true
        },
        dateLastModified: {
            type: String,
            required: true
        },
        itemsCount: {
            type: Number,
            required: true
        }
    }
};
</script>
<style module>
.fileIcon {
    width: 12px;
    position: relative;
    height: 12px;
    overflow: hidden;
    flex-shrink: 0;
}

.text {
    position: relative;
    line-height: 150%;
    font-weight: 500;
}

.link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.link2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #6b7280;
}

.breadcrumb {
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.breadcrumbHeading {
    width: 1258px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.breadcrumbStats {
    align-self: stretch;
    height: 53px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 16px 16px 0px;
    box-sizing: border-box;
    font-size: 14px;
    color: #374151;
}

.welcomeX {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.pageHeader {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1275px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;
    box-sizing: border-box;
}

.pageHeaderWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1275px;
    height: 56px;
}

.contentLayoutsInner {
    width: 1275px;
    position: relative;
    height: 56px;
    font-size: 36px;
    font-family: 'Roboto Flex';
}

.cardHeaderIcon {
    align-self: stretch;
    border-radius: 8px;
    max-width: 100%;
    overflow: hidden;
    height: 224px;
    flex-shrink: 0;
    object-fit: contain;
}

.header {
    align-self: stretch;
    height: 222px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 16px;
    box-sizing: border-box;
}

.profileCard {
    position: absolute;
    top: 0px;
    left: 0px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    width: 387px;
    height: 255.5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.profileCardWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 387px;
    height: 255.5px;
}

.groupContainer {
    width: 387px;
    position: relative;
    height: 255.5px;
}

.shareAllOutlineIcon {
    width: 12px;
    position: relative;
    height: 12px;
}

.button {
    position: absolute;
    top: 0px;
    left: 86px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 8px;
}

.button1 {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 8px;
}

.buttonParent {
    position: absolute;
    top: 0px;
    left: 585px;
    width: 175px;
    height: 40px;
}

.badge {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 6px;
    background-color: #fde8e8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 12px;
    gap: 4px;
    color: #803131;
}

.badge1 {
    position: absolute;
    top: 0px;
    left: 205px;
    border-radius: 6px;
    background-color: #d5c6e9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 12px;
    gap: 4px;
}

.badge2 {
    position: absolute;
    top: 0px;
    left: 88px;
    border-radius: 6px;
    background-color: #d5c6e9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 12px;
    gap: 4px;
}

.badgeParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 292px;
    height: 25px;
}

.groupFrame {
    position: absolute;
    top: 7.45px;
    left: 0px;
    width: 292px;
    height: 25px;
    text-align: center;
    font-size: 14px;
    color: #2d065d;
}

.groupParent1 {
    position: absolute;
    top: 0px;
    left: 0.06px;
    width: 760px;
    height: 40px;
}

.title {
    position: absolute;
    top: 52px;
    left: 0px;
    line-height: 150%;
    font-family: Roboto;
    color: #1b1128;
    display: inline-block;
    width: 760.1px;
}

.aComprehensiveFramework {
    margin: 0;
}

.preview {
    position: absolute;
    top: 88px;
    left: 0px;
    font-size: 20px;
    line-height: 150%;
    color: #6b7280;
    display: inline-block;
    width: 757px;
}

.checkCircleIcon {
    width: 20px;
    position: relative;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.publishedOnJan {
    position: relative;
    line-height: 150%;
}

.likes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.likes2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    font-family: Inter;
}

.commentsSave {
    width: 1000px;
    height: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.commentsSaveWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.frameWrapper1 {
    width: 1000px;
    margin: 0 !important;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0px;
    box-sizing: border-box;
    z-index: 0;
}

.frameDiv {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1000px;
    height: 45px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 4px 1px;
    box-sizing: border-box;
}

.frameContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1000px;
    height: 45px;
    font-size: 14px;
    color: #6b7280;
    font-family: Roboto;
}

.button2 {
    position: absolute;
    top: 4.63px;
    left: 628.06px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 8px;
}

.groupParent2 {
    position: absolute;
    top: 250px;
    left: 0px;
    width: 1000px;
    /* width: 757.1px; */
    /* height: 45px; */
}

.groupDiv {
    width: 760.1px;
    position: relative;
    height: 295px;
}

.groupParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1211px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.groupWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1211px;
    height: 295px;
}

.cardInner {
    align-self: stretch;
    position: relative;
    height: 295px;
}

.card {
    width: 100%;
    border-radius: 8px;
    background-color: #faf9fb;
    border: 1px solid #e8e6eb;
    box-sizing: border-box;
    /* height: 356px; */
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    font-size: 16px;
    color: #562990;
}

.text10 {
    position: relative;
    line-height: 24px;
}

.text11 {
    position: relative;
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    color: #6b7280;
}

.info {
    width: 14px;
    position: relative;
    height: 14px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.heading {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.headingWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 140px;
    height: 24px;
}

.groupWrapper2 {
    position: absolute;
    top: 0.5px;
    left: 0px;
    width: 140px;
    height: 24px;
}

.firstLevelInner {
    align-self: stretch;
    position: relative;
    height: 25px;
}

.firstLevel {
    width: 310px;
    border-bottom: 1px solid #e5e7eb;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.button4 {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    background-color: #562990;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 8px;
}

.buttonWrapper {
    width: 70px;
    position: relative;
    height: 37px;
    font-size: 14px;
    color: #fff;
}

.firstLevelParent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.searchOutlineIcon {
    width: 16px;
    position: relative;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
}

.inputText {
    flex: 1;
    position: relative;
    line-height: 150%;
}

.content1 {
    width: 332px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.input1 {
    align-self: stretch;
    border-radius: 8px 0px 0px 8px;
    background-color: #faf9fb;
    border: 1px solid #d6d2db;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 16px;
}

.inputField {
    width: 384px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.button5 {
    border-radius: 0px 8px 8px 0px;
    background-color: #562990;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    color: #fff;
    font-family: Inter;
}

.input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: #6b7280;
    font-family: Roboto;
}

.button6 {
    border-radius: 8px;
    background-color: #faf9fb;
    border: 1px solid #9678bc;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 8px;
}

.inputButtons {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.inputButtonsWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 775px;
    height: 37px;
}

.groupWrapper4 {
    width: 775px;
    position: relative;
    height: 37px;
}

.groupWrapper3 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1227px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.tableHeaderInner {
    width: 1227px;
    position: relative;
    height: 37px;
    font-size: 14px;
    color: #562990;
}

.tableHeader {
    align-self: stretch;
    border-radius: 8px 8px 0px 0px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px;
    gap: 24px;
}

.toggleText {
    width: 615px;
    position: relative;
    line-height: 16px;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}

.iconHeading {
    width: 639px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.accordionButton {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px 8px 0px 0px;
    background-color: #f4f3f6;
    border: 1px solid #e8e6eb;
    box-sizing: border-box;
    width: 1243px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.toggleText1 {
    align-self: stretch;
    position: relative;
    line-height: 150%;
    white-space: pre-wrap;
}

.buttonContainer {
    position: absolute;
    top: 0px;
    left: 1085.95px;
    width: 119px;
    height: 40px;
}

.badge3 {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 6px;
    background-color: #d5c6e9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 12px;
    gap: 4px;
}

.badge4 {
    position: absolute;
    top: 0px;
    left: 202.63px;
    border-radius: 6px;
    background-color: #d5c6e9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 12px;
    gap: 4px;
}

.badge5 {
    position: absolute;
    top: 0px;
    left: 101.32px;
    border-radius: 6px;
    background-color: #d5c6e9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 12px;
    gap: 4px;
}

.badgeGroup {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 293.6px;
    height: 25px;
}

.groupWrapper5 {
    position: absolute;
    top: 10.45px;
    left: 0px;
    width: 293.6px;
    height: 25px;
    text-align: center;
    font-size: 14px;
    color: #2d065d;
}

.groupParent4 {
    width: 1204.9px;
    position: relative;
    height: 40px;
    color: #562990;
    font-family: Inter;
}

.accordionBody {
    position: absolute;
    top: 56px;
    left: 0px;
    background-color: #fff;
    border: 1px solid #e8e6eb;
    box-sizing: border-box;
    width: 1243px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    gap: 10px;
    color: #6b7280;
    font-family: Roboto;
}

.accordionButtonParent {
    align-self: stretch;
    position: relative;
    height: 194px;
}

.basic {
    position: relative;
    line-height: 150%;
    font-weight: 600;
}

.headingBadge {
    width: 58px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.codeForkParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.accordionButton1 {
    align-self: stretch;
    background-color: #fff;
    border: 1px solid #e8e6eb;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    font-size: 18px;
}

.checkbox1 {
    width: 16.5px;
    border-radius: 4px;
    background-color: #faf9fb;
    border: 0.5px solid #d6d2db;
    box-sizing: border-box;
    height: 16.5px;
}

.checkbox {
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.flagOutlineIcon {
    width: 16px;
    position: relative;
    height: 16px;
}

.heading1 {
    width: 995px;
    position: relative;
    line-height: 150%;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}

.flagTask {
    width: 1017px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.checkboxHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.taskCard {
    position: absolute;
    top: 0px;
    left: 0px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    width: 1211px;
    height: 72.3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    box-sizing: border-box;
}

.taskCardWrapper {
    align-self: stretch;
    position: relative;
    height: 72.3px;
}

.plusIcon {
    width: 14px;
    position: relative;
    height: 14px;
    overflow: hidden;
    flex-shrink: 0;
}

.addTask {
    position: absolute;
    top: -0.5px;
    left: -0.5px;
    border-radius: 8px;
    border: 1px dashed #d6d2db;
    box-sizing: border-box;
    width: 1212px;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 8px;
}

.addTaskWrapper {
    align-self: stretch;
    position: relative;
    height: 44px;
    color: #6b7280;
}

.groupParent5 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}

.frameWrapper4 {
    align-self: stretch;
    border-right: 1px solid #e8e6eb;
    border-left: 1px solid #e8e6eb;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;
}

.accordionButton2 {
    align-self: stretch;
    background-color: #fff;
    border: 1px solid #e8e6eb;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.frameWrapper5 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 16px;
    font-size: 16px;
}

.accordionButtonGroup {
    align-self: stretch;
    border-right: 1px solid #e8e6eb;
    border-left: 1px solid #e8e6eb;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 0px;
    gap: 16px;
    font-size: 18px;
}

.taskCardWrapper3 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1211px;
    height: 72.3px;
}

.taskCardWrapper4 {
    position: absolute;
    top: 80.35px;
    left: 0px;
    width: 1211px;
    height: 72.3px;
}

.taskCardWrapper5 {
    position: absolute;
    top: 160.7px;
    left: 0px;
    width: 1211px;
    height: 72.3px;
}

.addTaskFrame {
    position: absolute;
    top: 241.05px;
    left: 0px;
    width: 1211px;
    height: 44px;
    color: #6b7280;
}

.groupParent7 {
    align-self: stretch;
    position: relative;
    height: 285px;
}

.frameGroup {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.heading13 {
    width: 842px;
    position: relative;
    line-height: 150%;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}

.flagTask12 {
    width: 864px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.checkboxHeading12 {
    width: 1045px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.taskCard12 {
    align-self: stretch;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    height: 72.4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    box-sizing: border-box;
}

.taskCardWrapper6 {
    width: 1211px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.heading14 {
    width: 930px;
    position: relative;
    line-height: 150%;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}

.flagTask13 {
    width: 960px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.checkboxHeading13 {
    width: 992px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.taskCard13 {
    width: 1127px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px 24px;
    box-sizing: border-box;
}

.taskCard14 {
    width: 1127px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    box-sizing: border-box;
}

.taskCardParent1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1143px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}

.frameWrapper10 {
    width: 1143px;
    position: relative;
    height: 314px;
}

.checkboxHeading17 {
    position: absolute;
    top: 24px;
    left: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.chevronDownIcon20 {
    position: absolute;
    top: 42px;
    left: 1173px;
    width: 12px;
    height: 12px;
    overflow: hidden;
}

.taskCard17 {
    position: absolute;
    top: 0.15px;
    left: 0px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    width: 1210px;
    height: 96px;
}

.taskCardWrapper7 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1226px;
    height: 96.5px;
}

.addTask3 {
    align-self: stretch;
    border-radius: 8px;
    background-color: #d5c6e9;
    border: 1px dashed #42167a;
    box-sizing: border-box;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 8px;
}

.addTaskWrapper1 {
    position: absolute;
    top: 104.15px;
    left: 0px;
    width: 1210px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #42167a;
}

.frameParent4 {
    width: 1226px;
    position: relative;
    height: 148.1px;
}

.frameParent3 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 8px;
}

.frameWrapper8 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1227px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.frameWrapper7 {
    width: 1227px;
    position: relative;
    height: 550.5px;
}

.accordionButton5 {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    border: 1px solid #e8e6eb;
    box-sizing: border-box;
    width: 1243px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.accordionButtonWrapper {
    align-self: stretch;
    position: relative;
    height: 56px;
}

.iconHeading2 {
    width: 639px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.accordionButton9 {
    align-self: stretch;
    border-radius: 0px 0px 8px 8px;
    background-color: #fff;
    border: 1px solid #e8e6eb;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.groupParent8 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 12px;
    font-size: 16px;
}

.accordionButtonParent1 {
    align-self: stretch;
    border-radius: 0px 0px 8px 8px;
    border-right: 1px solid #e8e6eb;
    border-left: 1px solid #e8e6eb;
    box-sizing: border-box;
    height: 886px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 0px;
    gap: 16px;
    font-size: 18px;
}

.frameParent {
    align-self: stretch;
    border-radius: 0px 0px 8px 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.tasks {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 35px;
}

.content2 {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 1275px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Aligns children items to the left */
    justify-content: flex-start;
    /* Aligns children items to the top */
}

.contentWrapper {
    position: relative;
    height: 2376.5px;
    font-size: 16px;
    color: #1b1128;
    /* Remove align-self if not needed, as it's usually used in flex containers */
}

.tableHeaderParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #111928;
}

.stars {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
}

.span {
    line-height: 14px;
}

.reviews1 {
    text-decoration: underline;
    line-height: 150%;
    color: #1b1128;
}

.count {
    position: relative;
    font-weight: 500;
}

.reviews {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    font-size: 14px;
    color: #6b7280;
}

.heading19 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.outOf {
    color: #6b7280;
}

.buttonprimarybasedefaultfa {
    border-radius: 8px;
    background-color: #42167a;
    border: 1px solid #42167a;
    box-sizing: border-box;
    height: 43px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
}

.reviewScore {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.div {
    width: 10px;
    position: relative;
    line-height: 14px;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}

.starNumber {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}

.shape {
    width: 350px;
    position: relative;
    border-radius: 2px;
    background-color: #e8e6eb;
    height: 6px;
    z-index: 0;
}

.shape1 {
    width: 100%;
    position: absolute;
    margin: 0 !important;
    right: 0%;
    bottom: 0px;
    left: 0%;
    border-radius: 2px;
    background-color: #e8d076;
    height: 6px;
    z-index: 1;
}

.progressBars {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
    gap: 4px;
}

.reviews2 {
    position: relative;
    line-height: 14px;
    font-weight: 500;
    color: #42167a;
}

.shape2 {
    align-self: stretch;
    position: relative;
    border-radius: 2px;
    background-color: #e8e6eb;
    height: 6px;
}

.progressBars1 {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}

.ratings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    font-size: 14px;
}

.reviewScoreRatings {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
}

.november182023 {
    position: relative;
    font-size: 14px;
    line-height: 150%;
    font-family: Roboto;
    color: #6b7280;
}

.nameDate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
}

.textBadge {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    font-size: 14px;
    font-family: Roboto;
}

.author {
    width: 288px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}

.myOldImac {
    align-self: stretch;
    position: relative;
    line-height: 150%;
}

.wasItHelpful {
    position: relative;
    line-height: 150%;
    font-weight: 500;
    color: #6b7280;
}

.input2 {
    width: 17px;
    border-radius: 8px;
    background-color: #faf9fb;
    border: 0.5px solid #d6d2db;
    box-sizing: border-box;
    height: 17px;
}

.writeLabelText {
    position: relative;
    line-height: 14px;
    font-weight: 500;
}

.labelHelper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.radio {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.radioInputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    font-size: 14px;
    color: #1b1128;
    font-family: Inter;
}

.message {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    color: #6b7280;
    font-family: Roboto;
}

.comment {
    align-self: stretch;
    border-bottom: 1px solid #e8e6eb;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 0px;
    gap: 12px;
}

.imageIcon {
    width: 80px;
    position: relative;
    border-radius: 8px;
    height: 120px;
    object-fit: cover;
}

.images {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}

.comment4 {
    align-self: stretch;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 0px;
    gap: 12px;
}

.comments1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 16px;
}

.buttonalternativebasedefaul {
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #e8e6eb;
    box-sizing: border-box;
    height: 43px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 14px;
}

.container {
    align-self: stretch;
    height: 1209px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 24px;
    box-sizing: border-box;
    gap: 24px;
}

.productReviews {
    align-self: stretch;
    background-color: #fff;
    height: 1401px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 96px 0px;
    box-sizing: border-box;
}

.contentLayouts {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.content {
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    box-sizing: border-box;
    text-align: left;
    font-size: 24px;
    color: #1b1128;
    font-family: Inter;
}
.spotlightTitle {
    font-size: x-large;
}
/* 
.cass--main-layout--primary-top {
    
    width:calc(100% - 64px);
    top: 40px; 
    left: 40px;
    padding: 0rem 1rem;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    background-color: white;
    z-index:30;
} */
</style>